var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "graphShadow paddingT10 paddingLR20" },
    [
      _c("graphTitle", { attrs: { title: "充值消费趋势" } }),
      _c("div", { style: { height: "300px" }, attrs: { id: "myChart" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }