<template>
  <div class="graphShadow paddingT10 paddingLR20">
    <graphTitle :title="'充值消费趋势'"></graphTitle>
    <div id="myChart" :style="{height: '300px'}"></div>
  </div>
</template>

<script>
  import graphTitle from "@/components/graphTitle";
  export default {
    props: {
      chartData: {
        default: function () {
          return {};
        }
      }
    },
    components: {
      graphTitle
    },
    mounted() {
      this.initChart();
    },
    watch: {
      chartData: {
        handler(ne) {
          this.updatedData();
        }
      }
    },
    methods: {
      updatedData() {
        this.myChart.clear();
        this.myChart.setOption({
          legend: {
            bottom: 10,
            data: ['本期充值', '本期余额消费', '本期余额退款']
          },
          tooltip: {
            show: true,
            trigger: 'axis',
            formatter(params) {
              let res = '';
              params.forEach(v => {
                res += `${v.marker + v.seriesName}:  ${v.value} 元<br/>`;
              });
              return res;
            }
          },
          grid: {
            top: '8%',
            left: '3%',
            right: '4%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.chartData.xAxis
          },
          yAxis: {},
          series: [
            {
              name: '本期充值',
              type: 'line',
              data: this.chartData.topUp
            },
            {
              name: '本期余额消费',
              type: 'line',
              data: this.chartData.balanceConsumption
            },
            {
              name: '本期余额退款',
              type: 'line',
              data: this.chartData.balanceRefund
            }
          ]
        });
      },
      initChart() {
        const myChart = this.$echarts.init(document.getElementById('myChart'));
        this.myChart = myChart;
        this.updatedData();
      }
    }
  };
</script>
