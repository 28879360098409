<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <el-form
        :inline="true"
        status-icon
        label-position="right"
        label-width="96px"
        :model="formInline"
        ref="formInline"
        class="demo-form-inline bgFFF paddingT20 marginB20"
      >
        <el-form-item :label="$t('searchModule.date')" prop="dateType" style="width: 200px">
          <el-select v-model.trim="formInline.dateType" filterable size="15" style="width: 100px">
            <el-option label="日" value="1">日</el-option>
            <el-option label="月" value="2">月</el-option>
            <!--<el-option label="年" value="year">年</el-option>
                         <el-option label="自定义" value="custom">自定义</el-option>-->
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-form-item>
            <el-date-picker
              unlink-panels
              v-show="formInline.dateType == '1'"
              v-model="dateRange"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
            <el-date-picker
              v-show="formInline.dateType == '2'"
              unlink-panels
              value-format="yyyy-MM"
              v-model="monthRange"
              :picker-options="pickerOptions"
              type="monthrange"
              :editable="false"
              :clearable="false"
              range-separator="至"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
            >
            </el-date-picker>
          </el-form-item>
        </el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchData()" :loading="loading"
          >{{ $t('button.search') }}</el-button
        >
        <el-button type="primary" plain icon="el-icon-search" @click="clear" :loading="loading"
          >{{ $t('button.reset') }}</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-upload2"
          @click="exportExcelReport()"
          :loading="loading"
          v-if="$route.meta.authority.button.export"
          >导出
        </el-button>
      </el-form>
      <div class="statics marginB20">
        <div class="statics-item">
          <div class="item-content graphShadow">
            <div><i class="iconfont iconyue1"></i><span class="title">期初余额</span></div>
            <div class="price">{{ moneyTofix(statics.beginBlanceTotal) }}</div>
          </div>
        </div>
        <div class="statics-item">
          <div class="item-content graphShadow">
            <div><i class="iconfont iconchongzhi2"></i><span class="title">本期充值</span></div>
            <div class="price">{{ moneyTofix(statics.rechargeTotal) }}</div>
          </div>
        </div>
        <div class="statics-item">
          <div class="item-content graphShadow">
            <div><i class="iconfont iconxiaofei"></i><span class="title">本期余额消费</span></div>
            <div class="price">{{ moneyTofix(statics.blanceConsumerTotal) }}</div>
          </div>
        </div>
        <div class="statics-item">
          <div class="item-content graphShadow">
            <div>
              <i class="iconfont iconchongzhi" style="margin-left: -17px"></i
              ><span class="title" style="margin-left: -25px">本期余额退款</span>
            </div>
            <div class="price">{{ moneyTofix(statics.blanceRefundTotal) }}</div>
          </div>
        </div>
        <div class="statics-item">
          <div class="item-content graphShadow">
            <div><i class="iconfont iconkeyongyue"></i><span class="title">期末余额</span></div>
            <div class="price">{{ moneyTofix(statics.endBlanceTotal) }}</div>
          </div>
        </div>
      </div>
      <CircularChart :chartData="chartData"></CircularChart>
      <!--列表区域-->
      <div class="tableWrapper bgFFF marginTB20 paddingT10 paddingB10">
        <graphTitle :title="'充值统计详情'" class="marginB20 paddingL20"></graphTitle>
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <div class="pagerWrapper" v-if="total > 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew, moneyTofix } from "@/common/js/public.js";
import CircularChart from "./chart";
import graphTitle from "@/components/graphTitle";

export default {
  name: "paySummaryReport",
  data() {
    return {
      chartData: {},
      page: 1,
      pageSize: 15,
      total: 0,
      moneyTofix,
      dateRange: [],
      monthRange: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now();
        },
      },
      statics: {},
      formInline: {
        dateType: "1",
        startTime: "",
        endTime: "",
      },
      loading: false,
      tableData: [],
      tableCols: [
        {
          prop: "calc_date",
          label: this.$t("list.date"),
          width: "",
        },
        {
          prop: "beginblance",
          label: "期初余额",
          width: "",
          formatter: (row) => {
            return moneyTofix(row.beginblance);
          },
        },
        {
          prop: "recharge",
          label: "本期充值",
          width: "",
          formatter: (row) => {
            return moneyTofix(row.recharge);
          },
        },
        {
          prop: "blanceconsumer",
          label: "本期余额消费",
          width: "",
          formatter: (row) => {
            return moneyTofix(row.blanceconsumer);
          },
        },
        {
          prop: "blancerefund",
          label: "本期余额退款",
          width: "",
          formatter: (row) => {
            return moneyTofix(row.blancerefund);
          },
        },
        {
          prop: "endblance",
          label: "期末余额",
          width: "",
          formatter: (row) => {
            return moneyTofix(row.endblance);
          },
        },
      ],
    };
  },
  methods: {
    initDate() {
      let startDate = this.$moment().subtract(30, "days").format("YYYY-MM-DD");
      let endDate = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
      let startMonth = this.$moment().subtract(11, "months").format("YYYY-MM");
      let endMonth = this.$moment().format("YYYY-MM");
      this.dateRange = [startDate, endDate];
      this.monthRange = [startMonth, endMonth];
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getTable();
    },
    clear() {
      this.initDate();
      this.formInline = {
        dateType: "1",
        startTime: "",
        endTime: "",
      };
    },
    searchData() {
      let startTime = "";
      let endTime = "";
      try {
        if (this.formInline.dateType == 1) {
          [startTime = "", endTime = ""] = this.dateRange;
        } else {
          [startTime = "", endTime = ""] = this.monthRange;
        }
      } catch (error) {
        this.$alert("日期不能为空！");
      }
      this.formInline.startTime = startTime;
      this.formInline.endTime = endTime;
      this.getStatics();
      this.getTable();
      this.getTrend();
    },
    getStatics() {
      this.$axios
        .get("/acb/2.0/elceRechargeStatistics/listStatistics", {
          data: {
            ...this.formInline,
          },
        })
        .then((res) => {
          this.statics = res.value || {};
        });
    },
    handleData(value = []) {
      let xAxis = [];
      let topUp = [];
      let balanceConsumption = [];
      let balanceRefund = [];
      value.forEach((v) => {
        xAxis.push(v.calc_date);
        topUp.push(moneyTofix(v.recharge, false));
        balanceConsumption.push(moneyTofix(v.blanceconsumer, false));
        balanceRefund.push(moneyTofix(v.blancerefund, false));
      });
      this.chartData = {
        xAxis,
        topUp,
        balanceConsumption,
        balanceRefund,
      };
    },
    getTable() {
      this.$axios
        .get("/acb/2.0/elceRechargeStatistics/list", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.tableData = res.value.list || [];
          this.total = res.value && res.value.total * 1;
        });
    },
    getTrend() {
      this.$axios
        .get("/acb/2.0/elceRechargeStatistics/figureList", {
          data: {
            ...this.formInline,
          },
        })
        .then((res) => {
          this.handleData(res.value);
        });
    },
    exportExcelReport() {
      let startTime = "";
      let endTime = "";
      try {
        if (this.formInline.dateType == 1) {
          [startTime = "", endTime = ""] = this.dateRange;
        } else {
          [startTime = "", endTime = ""] = this.monthRange;
        }
      } catch (error) {
        this.$alert("日期不能为空！");
      }
      exportExcelNew(
        "/acb/2.0/elceRechargeStatistics/export",
        {
          ...this.formInline,
          startTime,
          endTime,
        },
        "post"
      );
    },
  },
  components: {
    CircularChart,
    graphTitle,
  },
  mounted() {
    this.initDate();
    this.searchData();
    this.getTrend();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.content
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .list
    margin-top 20px
    .el-col
      padding-bottom 18px
      height 320px
      .grid-content
        padding-top 15px
        height 100%
      h2
        font-size 18px
        text-align center
  .statics
    display flex
    padding: 0 20px
  .title
    font-size: 20px
    font-weight 600
    line-height 30px
  .price
    font-size 25px
    font-weight 900
    line-height 50px
  .item-content
    border: 1px solid #bbb
    border-radius 10px
    padding 20px 0;
  .iconfont
    margin-right 20px
    font-size 60px
    color skyblue
    vertical-align middle

  .statics-item
    flex 1 0
    padding 0 15px
    text-align center
</style>
